/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../_helpers";
import moment from "moment-timezone";
// import CertificateLink from "../CertificateLink";

// function formattedDate(x) {
//     const originalTimestamp = new Date(x);
//     return originalTimestamp
// }

function numberWithCommas(x) {
    return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const shadeMappings = {
    "none": { label: "No.SH", tooltip: "Shade: NONE" },
    "brown": { label: "BR", tooltip: "Shade: Brown" },
    "light brown": { label: "L.BR", tooltip: "Shade: Light Brown" },
    "green": { label: "GR", tooltip: "Shade: Green" },
    "light green": { label: "L.GR", tooltip: "Shade: Light Green" },
    "grey": { label: "GREY", tooltip: "Shade: Grey" },
    "black": { label: "BLK", tooltip: "Shade: Black" },
    "pink": { label: "PINK", tooltip: "Shade: Pink" },
    "blue": { label: "BLUE", tooltip: "Shade: Blue" },
    "mix tinge": { label: "MIX", tooltip: "Shade: Mix Tinge" },
};

const renderShadeLabel = (shade) => {
    const mapping = shadeMappings[shade ? shade.toLowerCase() : ""];
    if (!mapping) return null;

    return (
        <span>
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip">{mapping.tooltip}</Tooltip>}
            >
                <span className={`label label-light-primary label-light-shade_${shade.toLowerCase().replace(' ', '_')} label-pill label-inline label-sm mr-1 font-weight-bold px-2`}>{mapping.label}</span>
            </OverlayTrigger>
        </span>
    );
};


export const MockDocTableColumn = (conversionrate,symbol,history,RouteToProforma,data,language,userinfo) => [
    {
        Header: language.CONFIRM_DIAMOND_ORDERNO,
        className: "align-middle text-center",
        accessor: (d) => {
            return (
                <>
                    <span>{d.conformGoodId || ""}</span>
                    <p className="m-0 small text-muted">
                        {/* {d.conformGoodId} */}
                        { d.created_date ? moment.tz(d.created_date, userinfo.timezone ? userinfo.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone).format("YYYY-MM-DD HH:mm:ss") : "-"}
                    </p>
                </>
            );
        },
    },
    {
        Header: language.CONFIRM_DIAMOND_DIAMONDDETAILS,
        accessor: (d) => (
            <>
            {d.diamondType === "PN" || d.diamondType === "PL" ? 
                <div className="row">
                    <div className="col-2 text-center p-0">
                        <img
                            className="img-fluid mr-2"
                            alt={d.shape}
                            src={toAbsoluteUrl(`/media/shape/svg_shape/parcel/${d.shape.toLowerCase()}.svg`)}
                            width="50px"
                            onError={e => {
                                e.currentTarget.src = toAbsoluteUrl(
                                    `/media/shape/svg_shape/parcel/other.svg`
                                    )
                                }}
                        />
                    </div>
                    <div className="col-10 pl-2 p-0 text-left">
                        <div className={`mb-1 ${d.diamondType === "PN" ? "natTab" : "labTab"}`}>
                            <span className={`${d.diamondType}_diamond mr-3 small`}>
                                {d.diamondType}
                            </span>
                            <span className={`cursor-pointer small ml-2`}>
                                {d.diamondType}-{d.stockID}
                            </span>
                            <span className={`text-capitalize small ml-2`}>
                                By: {d.parcelType === "carat" ? "Cts." : "Pcs."}
                            </span>
                            <span className={`text-capitalize small ml-2`}>
                            {d.parcelLength ? d.parcelLength.toFixed(2):"" }-{d.parcelWidth?d.parcelWidth.toFixed(2):"" } MM
                            </span>
                        </div>

                        <span className="text-dark font-weight-bold d-block">
                            {d.shape}{" "}
                            {d.weight ? d.weight.toFixed(2):""}Ct.{" "}
                            {d.parcelPieces}Pcs.{" "}
                            {d.color}{" "}
                            {d.clarity}{" "}
                        </span>
                    </div>
                </div>
             :
                <div className="row">
                    <div className="col-2 text-center p-0">
                        {d.image? (
                            <img
                                className="img-fluid mr-2"
                                alt={d.shape}
                                src={d.image}
                                width="50px"
                                onError={e => {
                                    e.currentTarget.src = toAbsoluteUrl(
                                        `/media/shape/${d.shape ? d.shape.toLowerCase() : ""}-no.png`
                                        )
                                    }}
                            />
                        ) : (
                            <img
                                className="img-fluid mr-2"
                                alt={d.shape}
                                src={toAbsoluteUrl(
                                    `/media/shape/${d.shape ? d.shape.toLowerCase() : ""}-no.png`
                                )}
                                onError={e => {
                                    e.currentTarget.src = toAbsoluteUrl(
                                        `/media/shape/noimage.jpg`
                                    )
                                }}
                                width="50px"
                                style={{ backgroundColor: "#f4f4f4" }}
                            />
                        )}
                    </div>
                    <div className="col-10 pl-2 p-0 text-left">
                    <div className={`mb-1 ${d.diamondType === "N" ? "natTab" : "labTab"}`}>
                            <span className={`${ d.diamondType === "N" ? d.C_Color === "fancy" ? "F" : "W" : d.C_Color === "fancy" ? "LF" : "L"  }_diamond mr-3 small`}>
                                {d.diamondType === "N" || d.diamondType === "W" ? d.C_Color === "fancy"  ? "F" : "N" : d.C_Color === "fancy" ? "LF" : "L"}
                            </span>
                            <a href={`/details/hc-${d.conformGoodId}`} target="_blank" rel="noreferrer" className={`cursor-pointer small ml-2`} >
                                {d.diamondType === "N" ? "N" : "L"}-{d.stockID}
                            </a>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                className="mx-2 small"
                                href={
                                    d.lab === "IGI"
                                        ? `https://www.igi.org/verify-your-report/?r=LG${d.certificate}`
                                        : d.lab === "GIA"
                                        ? `https://www.gia.edu/report-check?reportno=${d.certificate}`
                                        : d.lab === "HRD"
                                        ? `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${d.certificate}`
                                        : d.lab === "GCAL"
                                        ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${d.certificate}`
                                        : d.Certi_link
                                }
                            >
                                {d.lab}-{d.certificate}
                            </a>
                        </div>

                        <span className="text-dark font-weight-bold d-block">
                            {d.shape}{" "}
                            {d.weight}{" "}
                            {d.color}{" "}
                            {d.clarity}{" "}{d.cut}{" "}{d.polish}{" "}{d.symmetry}{" "}
                            {d.fluorescence}
                        </span>
                    </div>
                </div> 
                }
            </>
        ),
        className: "text-center align-middle",
        disableSortBy: true,
    },
    
    {
        Header: language.CONFIRM_DIAMOND_LOCATIONANDDELIVERY,
        accessor: (d) => (
            <>
                {d.country}
                <br />
                <span className="text-muted small">{d.shippingDays} days</span>
            </>
        ),
        className: "text-center align-middle",
        disableSortBy: true,
    },
    {
        Header: "Advanced Tags",
        accessor: (d) => (
            <div className="advTag" style={{ maxWidth: "150px" }}>
                {/* shade */}
                {renderShadeLabel(d.shade)}
                
                { d.milky && d.milky.toLowerCase() === "no milky" ?
                    <span>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    NO MILKY
                                </Tooltip>
                            }
                        >
                            <span className="label label-light-no_milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">NO.ML</span>
                        </OverlayTrigger>
                    </span>
                :
                    null
                }

                { d.milky && d.milky.toLowerCase() === "luster low" ?
                    <span>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    Luster Low
                                </Tooltip>
                            }
                        >
                            <span className="label label-light-luster_low label-pill label-inline label-sm  mr-1 font-weight-bold px-2">LL</span>
                        </OverlayTrigger>
                    </span>
                :
                    null
                }

                { d.milky && d.milky.toLowerCase() === "light milky" ?
                    <span>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    LIGHT MILKY
                                </Tooltip>
                            }
                        >
                            <span className="label label-light-light_milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">L.ML</span>
                        </OverlayTrigger>
                    </span>
                :
                    null
                }

                { d.milky && d.milky.toLowerCase() === "milky" ?
                    <span>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    MILKY
                                </Tooltip>
                            }
                        >
                            <span className="label label-light-milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">H.ML</span>
                        </OverlayTrigger>
                    </span>
                :
                    null
                }

                {/* eyeclean */}
                { d.eyeClean && d.eyeClean.toLowerCase() === "yes" ?
                    <span>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    100% Eye Clean
                                </Tooltip>
                            }
                        >
                            <span className="label label-light-info label-pill label-inline label-sm  mr-1 font-weight-bold px-2">100% EC</span>
                        </OverlayTrigger>
                    </span>
                :
                    null
                }
                { d.eyeClean && d.eyeClean.toLowerCase() === "no" ?
                    <span>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    No Eye Clean
                                </Tooltip>
                            }
                        >
                            <span className="label label-light-danger label-pill label-inline label-sm  mr-1 font-weight-bold px-2">EC: NO</span>
                        </OverlayTrigger>
                    </span>
                :
                    null
                }

                {/* growthtype */}
                { d.diamondType === "L" && (d.growthType && d.growthType === "CVD") ?
                    <span>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    CVD
                                </Tooltip>
                            }
                        >
                            <span className="label label-light-success label-pill label-inline label-sm  mr-1 font-weight-bold px-2">CVD</span>
                        </OverlayTrigger>
                    </span>
                :
                    null
                }
                { d.diamondType === "L" && (d.growthType && d.growthType === "HPHT") ?
                    <span>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    HPHT
                                </Tooltip>
                            }
                        >
                            <span className="label label-light-success label-pill label-inline label-sm  mr-1 font-weight-bold px-2">HPHT</span>
                        </OverlayTrigger>
                    </span>
                :
                    null
                }
                
                { (d.diamondType === "L" ) && (d.treatment && d.treatment.toLowerCase() === "as grown") ? 
                    <span>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    As Grown
                                </Tooltip>
                            }
                        >
                            <span className="label label-light-success label-pill label-inline label-sm  font-weight-bold px-2">As-G</span>
                        </OverlayTrigger>
                    </span>
                : 
                    null
                }
                {/* natural treated */}
                { (d.diamondType === "N" || d.diamondType === "W") && (d.treatment && d.treatment.toLowerCase() !== "none")  ? 
                    <span>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    Treated
                                </Tooltip>
                            }
                        >
                            <span className="label label-light-danger label-pill label-inline label-sm  font-weight-bold px-2">Tx</span>
                        </OverlayTrigger>
                    </span>
                : 
                    null
                }
            </div>
        ),
        className: "align-middle",
    },
    {
        Header: language.CONFIRM_DIAMOND_STATUS,
        accessor: (d) => (
            <div>
                <div
                    className={`label ${
                            d.diamondStatus === "Order Approve" ? "label-primary"
                            : d.diamondStatus === "Pending" ? "label-warning"
                            : d.diamondStatus === "Ready For Invoice" ? "label-success" 
                            : d.diamondStatus === "Ready to Ship" ? "label-success" 
                            : d.diamondStatus === "Rejected" ? "label-danger" 
                            : d.diamondStatus === "QC Rejected" ? "label-danger" 
                            : d.diamondStatus === "QC Review" ? "label-warning" 
                            : d.diamondStatus === "QC Approved" ? "label-success" 
                            : ""
                    } label-inline  mr-2`}
                >
                    {d.diamondStatus}
                </div>
            <br />
            {/* {d.proformaNo ? <Link to={{pathname:"proforma-detail",query:{proformaNo:d.proformaNo,conformGoodId:d.conformGoodId}}}  className="label label-light-info label-inline label-sm small" >Proforma generated</Link> : ""} */}
            {d.proformaNo ? <a onClick={() => RouteToProforma({query:{proformaNo:d.proformaNo,conformGoodId:d.conformGoodId}},data)} className="label label-light-info label-inline label-sm small">Pro Forma Inv No {d.perfoma_created_at}</a> : ""}
        
        </div>
        ),
        className: "text-center align-middle",
       
    },
    // {
    //     Header:'Diamond Journey',
    //     accessor:d => (
    //         <>
    //             <span className="font-weight-bolder">{d.canada_mark}</span><br />
    //             <span className="text-muted small">1-2 days</span>
    //         </>
    //       ),
    //     className:"text-center align-middle",
    // },
    {
        Header: language.CONFIRM_DIAMOND_DISCOUNT,
        className: "text-center text-danger align-middle",
        accessor: (d) => Number(d.discount).toFixed(2),
        sortMethod: (a, b) => Number(a) - Number(b),
        Cell: (row) => {
            return (
                <>
                    {row.row.original.diamondType === "PN" || row.row.original.diamondType === "PL" ? "-" :
                        <span className={`${(row.value >= 0) ? 'text-success' : 'text-danger'}`}>{row.value}%</span>
                    }
                </>
            );
        },
    },
    {
        Header: language.CONFIRM_DIAMOND_OURPRICEINRCT,
        accessor: (d) => (
            <>
                <span className="font-weight-bold">
                ₹{numberWithCommas(parseFloat(d["ourPriceExchange"]))}
                </span>{" "}
                <br />
                <span className="text-muted small">
                ₹/CT {numberWithCommas(parseFloat(d["ourRateExchange"]))}
                </span>
            </>
        ),
        className: "text-right pr-12 align-middle",
    },
    {
        Header: language.CONFIRM_DIAMOND_TRACK,
        accessor: (d) => (
            <>
                {d["tracking"]}
            </>
        ),
        className: "text-center align-middle",
    },
    // {
    //     Header:'Payment Status',
    //     accessor:'CUT',
    //     className:"text-center align-middle",
    // }
];
